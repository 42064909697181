<template>
  <div class="page-content d-flex align-items-center justify-content-center">
  </div>
</template>

<script>
export default {
  name: 'Login',
  metaInfo: {
    title: 'Login',
  },
  data() {
    return {
      email: '',
      password: '',
      alert_opt: {
        show: false,
        message: '',
      },
    };
  },
  mounted() {
    this.doLogin();
  },
  methods: {
    async doLogin() {
      const loader = this.$loading.show();
      const { redirect_token, fallback_url } = this.$route.query;
      await this.$store.dispatch('auth/doLoginCobrand', { token: redirect_token })
        .then(async (res) => {
          if (!res.error) {
            this.$router.push('/workspaces');
            this.$store.dispatch('ui/clearAlert');
          } else {
            window.location = fallback_url || 'https://makna.ai';
          }
        })
        .catch(async () => {
          window.location = fallback_url || 'https://makna.ai';
        });
      loader.hide();
    },
  },
};
</script>
